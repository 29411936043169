import 'jquery';
import 'jquery-ujs';
import 'slick-carousel';

$(() => {
  $('.pricing-plans button').click((event) => {
    if ($(event.target).hasClass('yearly-plans-btn')) {
      $('.pricing-plans .yearly-plans-btn').removeClass('btn-secondary').addClass('active btn-primary');
      $('.pricing-plans .monthly-plans-btn').removeClass('active btn-primary').addClass('btn-secondary');
      $('.pricing-plans .yearly-plan').removeClass('d-none');
      $('.pricing-plans .monthly-plan').addClass('d-none');
    } else {
      $('.pricing-plans .yearly-plans-btn').removeClass('active btn-primary').addClass('btn-secondary');
      $('.pricing-plans .monthly-plans-btn').removeClass('btn-secondary').addClass('active btn-primary');
      $('.pricing-plans .yearly-plan').addClass('d-none');
      $('.pricing-plans .monthly-plan').removeClass('d-none');
    }
  });

  $('.signInBtn').click((e) => {
    var planId = $(e.target).data('planId');
    localStorage.setItem('planId', planId);
    window.location.href = `/sign_in?plan_id=${planId}`;
  });

  $('.screenshots').slick({
    autoplay: true,
    dots: true,
    infinite: true,
  });
});
